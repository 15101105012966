import { create } from 'braintree-web-drop-in';

import { DonationForm } from './modules/donate/donate-form';
import { handleDonationAlertCta, getEnvironment } from './modules/utility';

const $donationForm = $('#donationform');
const authorizationInput = $('#clientToken');

if ($donationForm.length) {
  const environment = getEnvironment();
  // Hide the navbar donate button on the donation page [DONUT-2047]
  $('#donate').addClass('hidden');
  const $frequency = $("input[name='Frequency']");
  const $selectedAmount = $("input[name='SelectedAmount'], input[name='SelectedOtherAmount']");
  const $nonce = $('#nonce');
  const $deviceData = $('#deviceData');

  const urlParams = new URLSearchParams(window.location.search);
  const isRecurring = urlParams.get('isRecurring') === 'true';

  if (isRecurring) {
    $('#recurring').prop('checked', true);
  }

  //let showPaypalMethod = !isRecurring;
  let showApplePayMethod = !isRecurring;

  handleDonationAlertCta();

  const donationForm = new DonationForm();
  const donationAmountElement$ = $<HTMLDivElement>(".amount-stage");
  const donationAmount = donationAmountElement$.data("val") as string;


  // On an error the donationAmount will not be null
  if (donationAmount && ['25', '50', '100'].includes(donationAmount)) {
    // Select the previous selected amount
    $(`#amount-${donationAmount}`).prop('checked', true);
  } else if (donationAmount) {
    // Set the previous other amount
    $<HTMLInputElement>('#SelectedOtherAmount').val(donationAmount);
    donationForm.handleOtherAmount();
  }

  // Set the default to 100 if no donation amount
  if (donationAmountElement$.length > 0 && !donationAmount) {
    $<HTMLInputElement>('#amount-100').prop('checked', true);
  }

  const configureDropIn = async () => {
    const dropInInstance = await create({
      authorization: authorizationInput.val() as string,
      container: '#dropin-container',
      dataCollector: true,
    //   ...(showPaypalMethod && {
    //     paypal: {
    //       flow: 'vault',
    //     },
    //   }),
      ...(showApplePayMethod && {
        applePay: {
          displayName: "Children's Miracle Network Hospitals",
          paymentRequest: {
            total: {
              label: 'Donation',
              amount: '100',
            },
            requiredBillingContactFields: ['postalAddress'],
          },
        } as any,
      }),
    });

    // @ts-ignore
    const paymentOptions = dropInInstance.getAvailablePaymentOptions();

    const onFrequencyChangeHandler = async (event: JQuery.ChangeEvent) => {
      const isRecurring =
        (event.target as HTMLInputElement).value === 'recurring';
      showApplePayMethod = !isRecurring;
      //showPaypalMethod = !isRecurring;

      teardownHandlers();
      await dropInInstance.teardown();
      await configureDropIn();
    };
    const onSelectedAmountChange = (event: JQuery.ChangeEvent) => {
      const input = event.target as HTMLInputElement;
      const amount = input.value.toString()

      if (amount) {
        // this is required in order to charge the user the correct amount
        // @ts-ignore
        dropInInstance.updateConfiguration('applePay', 'paymentRequest', {
          total: {
            label: 'Donation',
            amount,
          },
        });
      }
    };

    const onDonationFormSubmit = async (event: JQuery.SubmitEvent) => {
      event.preventDefault();

      // grecaptcha won't exist on test
      if (typeof grecaptcha !== 'undefined' && !grecaptcha.getResponse()) {
        grecaptcha.execute();
        return;
      }

      const payload = await dropInInstance.requestPaymentMethod();
      if ($nonce) {
        $nonce.val(payload.nonce);
      }
      if ($deviceData) {
        $deviceData.val(payload.deviceData || '');
      }
      $donationForm.validate();
      if ($donationForm.valid()) {
        $donationForm.addClass('donate-form--submitting');
        $donationForm.off('submit', onDonationFormSubmit);
        $donationForm.trigger('submit');
        $donationForm.on('submit', onDonationFormSubmit);
      }
    };

    if (environment !== 'test' && $("#rechapchat_div").children().length === 0) {
      grecaptcha.render('rechapchat_div', {
        sitekey: $donationForm.data('cappySiteKey'),
        size: 'invisible',
        callback: () => {
          $donationForm.trigger('submit');
        },
      });
    }

    const teardownHandlers = () => {
      $frequency.off('change', onFrequencyChangeHandler);
      $selectedAmount.off('change', onSelectedAmountChange);
      $donationForm.off('submit', onDonationFormSubmit);
    };

    if (paymentOptions.includes('applePay')) {
      $selectedAmount.on('change', onSelectedAmountChange);
    }
    $frequency.on('change', onFrequencyChangeHandler);
    $donationForm.on('submit', onDonationFormSubmit);
  };

  configureDropIn();
}
