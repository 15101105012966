import { onlyNumbers } from '../utility';

export class DonationForm {
  private amountOther$ = $(".donation-amounts-other input");
  private donationAmounts$ = $(".donation-amounts");

  // These rules are for validating the create or existing account fields for recurring donations.
  // Due to the complexity of adding conditional requirements to these fields on the server side (e.g. IndexViewModel)
  // we will just implement them on the client side, and the form should not be able to submit until theses
  // fields are valid
  private billingFullNameInfoRules: JQueryValidation.RulesDictionary = {
    required: true,
    maxlength: 255,
    messages: {
      required: 'Full Name is required',
    },
  };

  private billingEmailInfoRules: JQueryValidation.RulesDictionary = {
    required: true,
    email: true,
    messages: {
      required: 'Email is required',
    },
  };

  private emailRules: JQueryValidation.RulesDictionary = {
    required: true,
    email: true,
    messages: {
      required: 'Email Address is required',
      email: 'Please enter a valid email address',
    },
  };

  private loginPasswordRules: JQueryValidation.RulesDictionary = {
    required: true,
    messages: {
      required: 'Password is required',
    },
  };

  private registrationNameRules: JQueryValidation.RulesDictionary = {
    required: true,
    maxlength: 255,
    messages: {
      required: 'Your Name is required',
    },
  };

  private registrationPasswordRules: JQueryValidation.RulesDictionary = {
    required: true,
    minlength: 8,
    complexity: true,
    messages: {
      required: 'Password is required',
    },
  };

  constructor() {
    const donationFrequency = $('.donation-frequency input[type=radio]:checked').val();

    this.addPasswordComplexityRule();
    this.adjustFormInputs((donationFrequency || '').toString());
    this.attachEventListeners();
    this.genericEvents();
  }

  /**
   * Handle all the donation amount logic
   */
  public handleOtherAmount = () => {
    //  Unselect amount and select text amount
    const checkedAmount$ = $(".donation-amounts input[type=radio]:checked");
    const amount = this.amountOther$.val();

    if (amount && +amount > 0) {
      // Add the selected class to the whole section
      $('.donation-amounts-other').addClass('donation-amounts-other--selected');

      // Remove checked from all the other donation buttons
      if (checkedAmount$.length > 0) {
        checkedAmount$.prop('checked', false);
      }

      return;
    }

    // If there is no amount and nothing is checked, select the $100 amount
    if ((!amount || +amount > 0) && checkedAmount$.length === 0) {
      const $amount100 = $("#amount-100");
      $amount100.prop('checked', true);
      $amount100.trigger('change');

      // Remove the selected class from the whole section
      $('.donation-amounts-other').removeClass('donation-amounts-other--selected');

      return;
    }
  };

  /**
   * Remove selected from other and reset the value to an empty string
   */
  private handleDonationAmounts = (e: Event) => {
    const button = e.target as HTMLInputElement;

    if (button.type === 'radio' && this.amountOther$.length > 0) {
      $('.donation-amounts-other').removeClass('donation-amounts-other--selected');
      this.amountOther$.val('')

      button.checked = true;
    }
  };

  /**
   * Just some general, generic events
   */
  private genericEvents() {
    const donationFrequency$ = $('.donation-frequency');
    const account$ = $<HTMLInputElement>("[name='Account']");
    const isUserLoggedIn$ = $<HTMLInputElement>("[name='IsUserLoggedIn']");
    const selectedOtherAmount = $<HTMLInputElement>("[name='SelectedOtherAmount']");
    
    if (account$.length > 0) {
      this.handleAccountFields(account$);
    }

    if (donationFrequency$.length > 0) {
      donationFrequency$.on('change', (e) => {
        this.handleRecurringFields(e);
        this.getAccountValue(account$, isUserLoggedIn$);
      });
    }

    this.addCommasSeparator(selectedOtherAmount);
  }
  
  private addCommasSeparator(otherAmount$:  JQuery<HTMLInputElement>){
    otherAmount$.each((_, element) => {
      element.addEventListener('blur', (e: Event) => {
        const target: HTMLInputElement = e.target as HTMLInputElement;

        let value = target.value;

        value = value.replace(/[^0-9.]/g, '');

        const parts = value.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        target.value = parts.join('.');
      });
    });
  }

  private handleAccountFields(account$: JQuery<HTMLInputElement>) {
    account$.each((_, element) => {
      element.addEventListener('change', (e: Event) => {
        const target: HTMLInputElement = e.target as HTMLInputElement;

        if (target.checked) {
          if (target.value === 'create') {
            this.addRegistrationValidationRules();
          } else if (target.value === 'existing') {
            this.addLoginValidationRules();
          }
        }
      });
    })
  }

  private getAccountValue(account: JQuery<HTMLInputElement>, isUserLoggedIn: JQuery<HTMLInputElement>) {
    if (!isUserLoggedIn.val()) {
      const create: HTMLInputElement = account[0];
      const existing: HTMLInputElement = account[1];
      if (create.checked) {
        this.addRegistrationValidationRules();
      } else if (existing.checked) {
        this.addLoginValidationRules();
      }
    }
  }

  /**
   * Hide and show the appropriate section for recurring donations
   */
  private handleRecurringFields(event: JQuery.ChangeEvent) {
    const freqButton = event.target as HTMLInputElement;

    if (freqButton?.type !== 'radio') {
      return;
    }

    if (freqButton.value === 'recurring' && freqButton.checked) {
      this.adjustFormInputs(freqButton.value);
    }

    if (freqButton.value === 'one-time' && freqButton.checked) {
      this.adjustFormInputs(freqButton.value);
      this.addBillingInfoValidationRules();
    }
  }

  private adjustFormInputs(frequency: string) {
    const accountForm$ = $('.inline-fend');
    const nameField$ = $('.donate-full-name');
    const emailField$ = $('.donate-email');

    if (frequency === 'recurring') {
      accountForm$.removeClass('hidden');
      nameField$.addClass('hidden');
      emailField$.addClass('hidden');
      nameField$.children('input').prop('required', false);
      emailField$.children('input').prop('required', false);
    } else {
      accountForm$.addClass('hidden');
      nameField$.removeClass('hidden');
      emailField$.removeClass('hidden');
      nameField$.children('input').prop('required', true);
      emailField$.children('input').prop('required', true);
    }
  }

  private attachEventListeners() {
    this.amountOther$.on('blur', this.handleOtherAmount);

    this.amountOther$.on('keypress', onlyNumbers);

    this.donationAmounts$.on('change', this.handleDonationAmounts)
  }

  private addLoginValidationRules() {
    $('#FullName').rules('remove');
    $('#Email').rules('remove');
    $('#RegistrationName').rules('remove');
    $('#RegistrationEmail').rules('remove');
    $('#RegistrationPassword').rules('remove');
    $('#LoginEmail').rules('add', this.emailRules);
    $('#LoginPassword').rules('add', this.loginPasswordRules);
  }

  private addRegistrationValidationRules() {
    $('#FullName').rules('remove');
    $('#Email').rules('remove');
    $('#LoginEmail').rules('remove');
    $('#LoginPassword').rules('remove');
    $('#RegistrationName').rules('add', this.registrationNameRules);
    $('#RegistrationEmail').rules('add', this.emailRules);
    $('#RegistrationPassword').rules('add', this.registrationPasswordRules);
  }

  private addBillingInfoValidationRules() {
    $('#FullName').rules('add', this.billingFullNameInfoRules);
    $('#Email').rules('add', this.billingEmailInfoRules);
    $('#LoginEmail').rules('remove');
    $('#LoginPassword').rules('remove');
    $('#RegistrationName').rules('remove');
    $('#RegistrationEmail').rules('remove');
    $('#RegistrationPassword').rules('remove');
  }

  private addPasswordComplexityRule() {
    $.validator.addMethod(
      'complexity',
      (p) => {
        let regex = /(\w)\1{2,}/g;
        return !regex.test(p);
      },
      'Password must not contain 3 or more repeated characters'
    );
  }
}
